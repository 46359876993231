footer {
  margin: 0;
  text-align: center;
  color: #eee;
  padding: 10px 0;
  background-color: transparentize($blue, 0.3);

  .book-buttons-bar {
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    height: 100%;
    top: 0;
    right: 0;

    .icons {
      display: flex;
      flex-direction: column;
      background-color: transparentize($blue, 0.3);
      border-radius: .5rem 0 0 .5rem;

      .title-booknow {
        text-align: center;
        text-transform: capitalize;
        width: 90px;
        padding: .5rem;
      }

      .booklink {
        &:hover {
          img {
            transform: rotate3d(0, 1, 1, 360deg);
          }
        }

        img {
          transition: all 1s;
          padding: 12px;
          filter: sepia(100%);
        }
      }

    }

    @media (max-width: $mobile-screen ) {
      position: relative;

      .icons {
        flex-direction: row;
        justify-content: space-around;

        .title-booknow {
          display: none;
        }
      }
    }
  }

  @media (max-width: $mobile-screen ) {
    position: fixed;
    bottom: 0;
    background-color: $blue;
    width: 100%;

    p.copyright-text {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
  }
}