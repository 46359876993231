/* Lettertypen importeren */
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Luxurious+Script&display=swap');

/* Variables */
@import './variables.scss';

/* Global scss for Index.js */
body {
  margin: 0;
  background-image: url('../images/photo-lumbung.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  font-family: 'Amaranth', sans-serif;

  #root {
    display: flex;
    flex-direction: column;
    position: relative;
    background: linear-gradient(175deg, transparentize($blue, .1), transparentize($gray, .1) 35%, transparentize($gold, .3));
    background-attachment: fixed;
    min-height: 100vh;
  }

  h2 {
    font-family: 'Luxurious Script';
    font-size: 72px;
    color: $gold;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin: 0 0 1rem 0;

    span {
      font-family: 'Amaranth', sans-serif;
      font-size: 36px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    color: $white;
    margin-bottom: 20px;
  }
}

/* Import component scss files */
@import './Header.scss';
@import './Main.scss';
@import './Footer.scss';
@import './Nav.scss';
@import './LanguageSwitcher.scss';
@import './Photos.scss';