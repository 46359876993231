header {
    nav {
        display: block;
        width: 100%;

        .menu-toggle {
            display: none;
        }

        @media (max-width: $mobile-screen ) {
            height: auto;
            right:-100vh;
            position: fixed;
            transition: right 0.3s ease-in-out;
            &.open {
                right:0;
                z-index: 9;
                top: 90px;
                height: calc( 100% - 200px );
                background: linear-gradient(175deg, rgba(0, 0, 25, 1), rgba(51, 51, 51, 1) 35%, rgba(255, 227, 176, 1));
            }

            .menu-toggle {
                display: block;
                position: fixed;
                right: 12px;
                top: 12px;
                border: none;
                background-color: transparent;
                img {
                    transition: all 2s;
                    padding-left: 0;
                }
            }
        }

        ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            padding: 0;

            @media (max-width: $mobile-screen ) {
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;
                display: none;

                &.open {
                    display: flex;
                    padding-top: 4rem;
                }

                li {
                    padding: .5rem 2rem;
                    &.hide {
                        display: none;
                    }
                }
            }

            li {
                a {
                    padding: .5rem 2rem;
                    border: solid transparent 2px;
                    border-right: none;
                    text-decoration: none;
                    font-size: 1.2rem;
                    font-family: 'Amaranth', sans-serif;
                    color: rgba(255, 227, 176, .7);

                    &.highlight {
                        background: linear-gradient(90deg, rgba(0, 0, 25, 0.5) 25%, rgba(0, 0, 0, 0));
                        ;
                        border: solid rgba(255, 227, 176, .7) 2px;
                        border-right: none;

                        &:hover {
                            border: none;
                            color: rgba(0, 0, 25, 1);
                            background: darken($color: rgba(255, 227, 176, .7), $amount: 10);
                        }
                    }

                    &:hover {
                        border: solid rgba(255, 227, 176, .7) 2px;
                        border-right: none;
                    }
                }
            }
        }

    }
}