header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    z-index: 9;
    img {
      max-height: 200px;
      width: auto;
      &.header-logo {
        display: block;
      }
      &.mobile-logo {
        display: none;
      }
    }
    @media ( max-width: $mobile-screen ) {
      position: fixed;
      top: 0;
      background-color: rgb(0,0,25);
      width: 100%;
      box-shadow: 0px 10px 20px rgba(255, 227, 176, .7);
      img {
        max-height: 90px;
        padding-left: 1rem;
        &.mobile-logo {
          display: block;
        }
        &.header-logo {
          display: none;
        }
      }
    }
  }
