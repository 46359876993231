.language-switcher {
    display: block;
    position: relative;
    cursor: pointer;
    button {
        background: none;
        border: none;
        padding: .5rem 1rem;
        min-width: 190px;
        cursor: pointer;
        &.button.hide {
            visibility: hidden;
        }
        img {
            width: 1.5rem;
            height: 1rem;
            margin-left: 0.5rem;
            vertical-align: middle;
        }
        span {
            font-size: 1.2rem;
            font-family: 'Amaranth', sans-serif;
            color: rgba(255, 227, 176, .7);
        }
    }
}

.dropdown-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: right;
    background: linear-gradient( 90deg, rgba(0, 0, 25, 1) 25%, rgba(0, 0, 25, 0.7) );
    border: 2px solid rgba(255, 227, 176, .7);
    border-radius: 0.25rem;
    box-shadow: 10px 10px 10px rgba(51, 51, 51, .7);
    &.open {
        display: block;
    }
}

.dropdown-item {
    display: block;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: rgba(255, 227, 176, .7);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover, &:focus {
        background-color: rgba(255, 227, 176, .7);
        color: #363636;
        text-decoration: none;
    }
    &.is-active {
        background-color: rgba(255, 227, 176, .7);
        color: #363636;
    }
    img {
        width: 1.5rem;
        height: 1rem;
        margin-left: 0.5rem;
        vertical-align: middle;
    }
}