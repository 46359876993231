main {
  flex-grow: 1;
  section {
    max-width: 1080px;
    margin: 50px auto;
    padding: 20px 50px;
    background: linear-gradient(90deg, transparentize($blue, .5) 25%, transparent);
    border: $gold solid;
    border-right: none;

    @media (max-width: $mobile-screen ) {
      padding: 0 20px;
      margin-top: 90px;
      margin-bottom: 110px;

      h2 {
        margin: 0;
      }
    }
  }

  img.home-featured {
    float: right;
    margin: 50px 1rem 1rem 1rem;

    @media (max-width: $mobile-screen ) {
      margin: 50px 0 0 0;
      height: auto;
      width: 100%;
    }
  }
}