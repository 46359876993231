/* Variables */
@import './variables.scss';

.google-rating {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: .5rem;
    border: $gold solid 4px;
    padding: 1rem;
    width: 25%;
    text-align: center;
    position: relative;
    @media ( max-width: $mobile-screen ) {
        width: auto;
    }
    .logo{
        background-image: url('../images/icon-google-maps-512x512.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 48px;
        width: 48px;
        position: absolute;
        top: -36px;
        background-color: $gold;
        border: 1rem solid $gold;
        border-radius: 50%;
    }
    h2 {
        span {
            font-size: 1.5rem;
            color: $blue;
        }
    }
    p {
        color: $blue;
    }
    img {
        filter: sepia(100%);
        max-height: 28px;
      }
    a {
        padding: .5rem 1rem;
        text-decoration: none;
        border: $gold solid 1px;
        border-radius: .5rem;
        background-color: transparentize($blue, .5);
        color: $gold;
        &:hover {
            color: $blue;
            background-color: $gold;
            border: $blue solid 1px;
        }
    }
}