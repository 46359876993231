/* Variables */
@import './variables.scss';

.lightbox {
    position: relative;
    cursor: pointer;
    aspect-ratio: 3/2;
    width: 30%;
    margin: 10px;
    overflow: hidden;
    border-radius: .25rem;
    background: linear-gradient(145deg, $blue, $light-gray );
    box-shadow: 5px 5px 10px $gray;

    @media (max-width: $mobile-screen ) {
        width: 45%;
        margin: 2.5%;
    }

    img {
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 1s;

        &:hover {
            transform: scale(1.1) translate(-50%, -50%);
        }
    }

    .close {
        display: none;
    }

    &.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparentize($blue, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        margin: 0;

        img {
            max-width: 100%;
            max-height: 100%;
            height: 80%;

            @media (max-width: $mobile-screen ) {
                height: auto;
                width: 100%;
            }

            &:hover {
                transform: translate(-50%, -50%);
            }
        }

        .close {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;

            img {
                top: 2rem;
                right: 2rem;
                left: unset;
                max-height: 2rem;
                max-width: 2rem;
            }
        }

        .title {
            position: absolute;
            font-size: 2.5rem;
            bottom: 1rem;
            left: 0;
            width: 100%;
            padding: .5rem;
            color: $white;
            text-align: center;
            transition: all 1s;
        }
    }
}

.title {
    bottom: -6rem;
}