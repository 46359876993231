/* Variables */
@import './variables.scss';

.reviews-section {
  display: flex;
  flex-direction: column;

  .reviews-carousel {
    position: relative;
    overflow: hidden;

    .review {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-radius: 8px;
      opacity: 0;
      transition: opacity 0.5s ease;
      color: $white;
      text-align: center;

      &.active {
        opacity: 1;
        position: relative;
      }

      .review-content {
        display: flex;
        gap: 1.5rem;
        align-items: center;
      }

      .review-text {
        min-height: 7rem;
        font-style: italic;
        font-size: 24px;
        font-weight: 100;
      }

      img {
        filter: sepia(100%);
        &.star-image {
          max-height: 50px;
        }
        @media ( max-width: $mobile-screen ) {
          max-height: 128px;
          &.star-image {
            max-height: 24px;
          }
        }
      }

      .reviewer-name {
        padding: 1rem;
      }
    }
  }
}